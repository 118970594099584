import * as Yup from 'yup';

import { EmailRegex, Messages } from '../../../../enum/enumList';

export const UpdateCustomerEmailValidator = Yup.object().shape({
  email: Yup.string()
    .required(Messages.required)
    .trim()
    .matches(EmailRegex, Messages.emailErr)
    .typeError(Messages.required),
});
